/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState, useEffect, memo } from "react";
import { useCountUp } from "react-countup";
import { motion, useAnimation } from "framer-motion";
import { useThemeUI } from "theme-ui";
import useInterval from "components/hooks/use-interval";
import { path } from "ramda";

import { randomInteger } from "utils/utils";

function Price(props) {
  const { amount, formatter } = props;
  const [priceColor, setColor] = useState("green");
  const [goingUp, setGoingUp] = useState(true);
  const controls = useAnimation();
  const themeContext = useThemeUI();

  const currentColor = path(["theme", "colors", priceColor], themeContext);

  const { countUp, update } = useCountUp({
    start: 0,
    end: amount,
    duration: 1,
    decimals: 2,
  });

  useEffect(() => {
    const goingUp = countUp <= amount;
    setGoingUp(goingUp);
    setColor(goingUp ? "green" : "red");
  }, [amount]);

  useEffect(() => {
    update(amount);
  }, [amount]);

  return (
    <div sx={{ display: "flex", alignItems: "center" }}>
      <span sx={{ color: priceColor, mr: "xs" }}>{formatter(countUp)}</span>
      <motion.div
        sx={{ opacity: 0.4, lineHeight: 1 }}
        animate={{
          color: currentColor,
          rotate: goingUp ? 0 : 180,
          y: goingUp ? [0, -10, 0] : [0, 10, 0],
        }}
        transition={{ duration: 0.2 }}
        style={{
          paddingTop: goingUp ? 4 : 0,
        }}
      >
        ↑
      </motion.div>
    </div>
  );
}

Price.defaultProps = {
  amount: 0,
  formatter: (val) => val,
};

export default memo(Price);
