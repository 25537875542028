/** @jsx jsx */
import { jsx, Grid, Text } from "theme-ui";

import { formatCurrency, getQuantity, fromBNDec } from "utils/utils";
import Price from "components/price";

import { brandId } from "data/products";

function ProductMeta(props) {
  const { product } = props;

  const { totalBuyPrice, totalSupply = 0, availableTokens = 0 } = product;

  return (
    <Grid gap={10} columns={2} mb="xl">
      <div>
        <Text variant="stnd.body" sx={{ mb: "xs" }}>
          Trading at
        </Text>
        <Text variant="stnd.s">
          <Price amount={fromBNDec(totalBuyPrice)} formatter={formatCurrency} />
        </Text>
      </div>
      <div sx={{ textAlign: "right" }}>
        <Text variant="stnd.body" sx={{ mb: "xs" }}>
          Available
        </Text>
        <Text variant="stnd.s">
          {Math.floor(fromBNDec(availableTokens))}/{totalSupply}
        </Text>
      </div>
    </Grid>
  );
}

export default ProductMeta;
