const { BRAND_ID } = process.env;

export const brandId = BRAND_ID;

export default [
  {
    id: "1-1",
    code: "$NEUE—001",
    slug: "neue-001",
    name: "Culture is Currency",
    category: "T-shirt",
    image: "/images/culture-is-currency.jpg",
    video: "/videos/culture-is-currency.mp4",
    poster: "/videos/culture-is-currency.jpg",
    tradingAt: 189.56,
    quantity: 50,
    sold: 26,
  },
  {
    id: "1-2",
    code: "$NEUE—002",
    slug: "neue-002",
    name: "BusinessWeek 03",
    category: "T-shirt",
    image: "/images/buy-low.jpg",
    video: "/videos/buy-low.mp4",
    poster: "/videos/buy-low.jpg",
    tradingAt: 45,
    quantity: 50,
    sold: 29,
  },
  {
    id: "1-3",
    code: "$NEUE—003",
    slug: "neue-003",
    name: "Cash Monet",
    image: "/images/cash-monet.jpg",
    video: "/videos/cash-monet.mp4",
    poster: "/videos/cash-monet.jpg",
    category: "T-shirt",
    tradingAt: 24.12,
    quantity: 50,
    sold: 16,
  },
  {
    id: "1-4",
    code: "$NEUE—004",
    slug: "neue-004",
    name: "Mystery item",
    image: "/images/004.jpg",
    video: "/videos/004.mp4",
    poster: "/videos/004.jpg",
    category: "To be revealed soon",

    isMystery: true,
    tradingAt: 189.56,
    quantity: 50,
    sold: 26,
  },
  {
    id: "1-5",
    code: "$NEUE—005",
    slug: "neue-005",
    name: "Mystery item",
    image: "/images/005.jpg",
    video: "/videos/005.mp4",
    poster: "/videos/005.jpg",
    category: "To be revealed soon",

    isMystery: true,
    tradingAt: 189.56,
    quantity: 50,
    sold: 26,
  },
  {
    id: "1-6",
    code: "$NEUE—006",
    slug: "neue-006",
    name: "Mystery item",
    image: "/images/006.jpg",
    video: "/videos/006.mp4",
    poster: "/videos/006.jpg",
    category: "To be revealed soon",

    isMystery: true,
    tradingAt: 189.56,
    quantity: 50,
    sold: 26,
  },
];
