/** @jsx jsx */
import { jsx, Text, Box, Heading } from "theme-ui";
import Link from "next/link";
import { useMedia } from "react-use";

import ProductMeta from "components/product-meta";
import ProductActions from "components/product-actions";
import ProductVideo from "components/product-video";

function Product(props) {
  const { product } = props;

  const isMobile = useMedia("(max-width: 720px), (hover: none)");

  const productVideo = isMobile ? (
    <Box as="img" sx={{ width: "100%" }} src={product.image} />
  ) : (
    <ProductVideo video={product.video} poster={product.image} />
  );

  return (
    <div className="f8n-product" data-brand-id={0} data-product-id={product.id}>
      <div sx={{ mb: "xl" }}>
        <Heading
          as="h2"
          variant="stnd.s"
          sx={{ mb: "xs", textAlign: "center" }}
        >
          {product.symbol}
        </Heading>
        <Text
          as="h3"
          sx={{
            fontSize: "sub",
            maxWidth: 220,
            mx: "auto",
            textAlign: "center",
          }}
        >
          {product.name}
        </Text>
      </div>

      <div sx={{ mb: "xl", maxWidth: 410, mx: "auto" }}>{productVideo}</div>

      <div sx={{ maxWidth: 320, mx: "auto" }}>
        {product.totalBuyPrice && <ProductMeta product={product} />}
        <ProductActions
          product={product}
          productVideo={productVideo}
          productId={product.id}
        />
      </div>
    </div>
  );
}

export default Product;
