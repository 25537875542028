import { addIndex, map } from "ramda";
import BigNumber from "bignumber.js";
import { compose } from "ramda";

export const toBase10 = (value) => value.div(10 ** 18);

export const toBase18 = (value) => value.times(10 ** 18);

// replace these with bignumber.js equivalents
export const fromBN = (n) => n.toNumber();
export const fromBNDec = compose(Number, toBase10, BigNumber);
export const toBNDec = (n) => new BigNumber(n);
export const toBN = (n) => new BigNumber(n).shiftedBy(18);
export const toBNFixed = (n) => new BigNumber(n).shiftedBy(18).toFixed();

// TODO: Add a .toFixed() to this helper to get these numbers into
// the format that contracts are expecting

// Note, there isn't a great way of detecting BN
// https://github.com/ethers-io/ethers.js/issues/595#issuecomment-526942767
// export const isBN = (n) => typeof n === 'object';

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";

export const getQuantity = ({ quantity, sold }) =>
  `${quantity - sold}/${quantity}`;

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatCurrency = formatter.format;

export const getCleanUrl = (str = "") => str.replace(/[^a-zA-Z ]/g, "");

export const repeatText = (text, length = 10) => {
  const textArr = new Array(length).fill(text);

  return textArr.join(" ").trim().replace(",", "");
};

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const mapIndexed = addIndex(map);

export const calculateInitialDuration = (endDate, today) => {
  const futureDate = new Date(endDate);

  const days = differenceInDays(futureDate, today);
  const hours = differenceInHours(futureDate, today) % 24;
  const minutes = differenceInMinutes(futureDate, today) % 60;
  const seconds = differenceInSeconds(futureDate, today) % 60;

  return { days, hours, minutes, seconds };
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
