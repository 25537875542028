/** @jsx jsx */
import { jsx } from "theme-ui";
import { transitions } from "data/theme";

function ProductVideo(props) {
  const { video, poster } = props;

  if (video) {
    return (
      <video
        sx={{
          width: "100%",
          maxWidth: "100%",
          display: "block",
          transition: transitions.smooth.fast,
          "@media (hover: hover)": {
            "&:hover": {
              transform: "translateY(-4px)",
            },
          },
        }}
        autoPlay
        muted
        loop
        controls={false}
        poster={poster}
      >
        <source src={video} type="video/mp4" />
      </video>
    );
  }

  return null;
}

export default ProductVideo;
