/** @jsx jsx */
import { jsx } from "theme-ui";

function InfoBlock(props) {
  const { children, ...rest } = props;

  return (
    <div
      sx={{
        maxWidth: 440,
        mx: "auto",
        textAlign: "center",
        px: ["m", null, "l"],
        boxSizing: "content-box"
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export default InfoBlock;
