/** @jsx jsx */
import { jsx, Grid, Button } from "theme-ui";
import { toLower, toUpper } from "ramda";

const BASE_URL = `https://foundation.app`;

const buildBrand = (product) => toLower(product?.brand?.symbol ?? "");
const buildSymbol = (product) => `$${toUpper(product?.symbol ?? "")}`;

const buildUrl = (product, mode) =>
  `${BASE_URL}/${buildBrand(product)}/${buildSymbol(product)}/${mode}`;

function ProductActions(props) {
  const { product } = props;

  const redeemable = product?.redeemable;

  return (
    <Grid gap={10}>
      <Button
        sx={{ gridColumn: "1/3", py: "s" }}
        as="a"
        href={buildUrl(product, "buy")}
      >
        Buy
      </Button>
      <Button sx={{ py: "xs" }} as="a" href={buildUrl(product, "sell")}>
        Sell
      </Button>
      {redeemable ? (
        <a
          as="a"
          href={buildUrl(product, "redeem")}
          sx={{ textDecoration: "none", color: "inherit", display: "block" }}
        >
          <Button sx={{ py: "xs", width: "100%" }}>Redeem</Button>
        </a>
      ) : (
        <Button sx={{ py: "xs" }} disabled={true}>
          Redeem
        </Button>
      )}
    </Grid>
  );
}

export default ProductActions;
