/** @jsx jsx */
import { jsx, Heading } from "theme-ui";
import { useEffect } from "react";
import { motion, useCycle, useAnimation } from "framer-motion";
import Marquee3k from "marquee3000";
import { useMeasure, useToggle, useInterval, useMedia } from "react-use";
import { repeatText, shuffle, randomInteger, mapIndexed } from "utils/utils";

const RandomChar = React.memo((props) => {
  const { delays, txnCode } = props;
  const [randomChar, setRandom] = useCycle(...shuffle(txnCode));
  useInterval(setRandom, randomInteger(...delays));
  return randomChar;
});

RandomChar.defaultProps = {
  txnCode: "0x5a0b54d5dc17e0aadc383d2db43b0a0d3e029c4c".split(""),
  delays: [40, 60],
};

const StaticChar = (props) => {
  const { char } = props;

  const [isActive, toggleActive] = useToggle(false);

  function animateChar() {
    toggleActive();
    const rand = randomInteger(250, 400);
    setTimeout(toggleActive, rand);
  }

  if (isActive) {
    return (
      <span style={{ cursor: "pointer" }}>
        <RandomChar delays={[50, 150]} />
      </span>
    );
  }

  return <span onMouseOver={animateChar}>{char}</span>;
};

function Banner(props) {
  const { children, isReversed, ...rest } = props;
  const [marqueeRef, { width }] = useMeasure();
  const isNarrow = useMedia("(max-width: 640px)");
  const [playState, cycleState] = useCycle(true, false);
  const mountControls = useAnimation();

  async function runAnimation(width) {
    await mountControls.start({
      x: -width / 2,
      transition: { duration: width / 300, ease: "linear" },
    });
    await mountControls.set({
      x: 0,
    });
  }

  useEffect(() => {
    runAnimation(width);
  }, [playState]);

  useEffect(() => {
    runAnimation(width);
  }, [width]);

  const bannerString = ` ${repeatText(children, 8)}`;

  const chars = mapIndexed(
    (char, index) => (
      <StaticChar char={char} index={index} key={`${index}-${char}`} />
    ),
    bannerString
  );

  // only on narrow screens
  useEffect(() => {
    if (isNarrow) Marquee3k.init();
  }, [isNarrow]);

  const reversedProps = isReversed && {
    "data-reverse": "bool",
  };

  if (isNarrow) {
    return (
      <div className="marquee3k" data-speed="1" {...reversedProps}>
        <Heading variant="banner" {...rest}>
          {children}
          <span style={{ opacity: 0 }}>|</span>
        </Heading>
      </div>
    );
  }

  return (
    <Heading
      {...rest}
      variant="banner"
      sx={{
        transform: isReversed ? "rotate(180deg)" : "translate(0,0)",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      <GhostBanner ref={marqueeRef}>{chars}</GhostBanner>

      <div sx={{ display: "flex", position: "relative" }}>
        <motion.div
          animate={mountControls}
          sx={{
            whiteSpace: "pre",
            willChange: "transform",
          }}
          onAnimationComplete={cycleState}
        >
          {chars}
        </motion.div>
      </div>
    </Heading>
  );
}

const GhostBanner = React.forwardRef((props, ref) => (
  <div sx={{ height: 0, overflow: "hidden", display: "flex" }}>
    <span sx={{ whiteSpace: "pre" }} ref={ref}>
      {props.children}
    </span>
  </div>
));

export default Banner;
